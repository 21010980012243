<template>
	<div class="page-error" v-bind="getAllData">
		<div v-if="locale">
			<div class="page-error-icon">
				<div><img src="/img/icons/apple-touch-icon-60x60.png" alt="Icono apple touch"/></div>
			</div>
			<div class="page-error-message">
				<div class="page-error-message-label">
					<h3>
						<i class="fa fa-exclamation-triangle"></i>
						{{ locale.error['404'].title }}
					</h3>
				</div>
				<div class="page-error-message-query">
					<span>{{ message }}</span>
				</div>
			</div>
			<div class="page-error-actions">
				<a href="/" class="primary-button">{{ locale.error.reload_btn }}</a>
			</div>
		</div>
	</div>
</template>

<script>
import store from '../store/index.js';
export default {
	data() {
		return {
			message: '',
			locale: null,
		};
	},
	methods: {
		async getdataLang() {
			this.locale = await store.getters['localeLang'];
		},
	},
	computed: {
		getAllData: async function () {
			this.getdataLang();
		},
	},
};
</script>

<style lang="scss">
@import '@/assets/styles/vars.scss';
.page-error {
	padding: 20px;
	text-align: center;
	&-message {
		margin: 10px 0;
		&-label {
			color: $red;
		}
		&-query {
			margin-top: 5px;
		}
	}
	&-icon {
		font-size: 250%;
		color: $red;
	}
	&-actions {
		margin: $mpadding;
		a {
			display: inline-block;
		}
	}
}
</style>
